.create-user {
  width: 100%;

  &__content {
    width: 100%;
    padding: 0.625rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  &__field-title {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__field-input {
    width: 100%;
    height: 40px;
    border: 1px solid transparent;

    &_valid_1 {
    }
    &_valid_2 {
      border: 1px solid green;
    }
    &_valid_3 {
      border: 1px solid red;
    }
  }

  &__button {
    height: 2.5rem;
    width: 100%;
  }
}
