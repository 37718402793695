.slots-bet-history-mobile {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__back-block-text {
    margin: 0 auto 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }
  &__back-block-right {
    display: flex;
    align-items: center;
  }
  &__back-block-arrow-icon,
  &__back-block-filter-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1.875rem;
      height: 1.875rem;
    }
  }
  &__back-block-arrow-icon {
    transform: scaleY(-1);
  }
  &__back-block-arrow-icon_opened {
    transform: scaleY(1);
  }

  &__title {
    margin: 0 0 1.375rem 0;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
    color: #797B83;
  }

  &__content {
    flex-grow: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    width: 100%;
    display: none;
  }
  &__filters_opened {
    display: block;
  }

  &__list {
    width: 100%;
    padding: 0.3125rem 0.3125rem 0 0.3125rem;
  }
  &__item {
    width: 100%;
    margin: 0 0 0.3125rem 0;
  }

  &__paginator-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 0;
    padding: 0.625rem 0;
  }
  &__paginator {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
