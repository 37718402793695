.change-child-pas {
  width: 100%;
  box-sizing: border-box;
  padding-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__error {
    margin-bottom: 1rem;
    color: red;
  }

  &__button {
    margin-top: 1.5rem;
    width: 100%;
    height: 2.5rem;
    background: #FFDF38;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }
  input {
    width: 100%;
    height: 2.5rem;
  }
}
