.menu-item {
  width: 100%;
  height: 2.1875rem;
  min-height: 2.1875rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;

  &__name {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #FFF;
  }

  &__sub-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__element {
    width: 100%;
    height: 3.125rem;
    min-height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #323232;
    color: #ffffff;

    &:not(:last-child) {
      margin: 0 0 1px 0;
    }

    &_active {
      background: #FFDF38;
      color: #111522;
    }
  }
  &__element-name {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}
