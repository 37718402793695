.auth {
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  &__title {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 0 1px 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
  &__title-text {
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__field {
    &:not(:last-child) {
      margin: 0 0 0.4375rem 0;
    }
  }
  &__field-title {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #FFF;
  }
  &__field-input {
    height: 2.5rem;
    width: 100%;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0.4375rem 0 0 0;
  }

  &__other {
    margin-bottom: 0.75rem;
    margin-left: 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    color: #FFF;
  }

  &__item {
    min-width: 22.5rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 0 1px 0;
    background: #323232;
    color: #ffffff;
    cursor: pointer;

    &_active {
      background: #FFDF38;
      color: #111522;

      & .SVG-component__content-svg * {
        fill: #323232;
      }
    }
  }

  &__item-img {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin-right: 0.625rem;
  }

  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;;
  }

  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin: 0 0 0 auto;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}
