.users-list {
  display: flex;
  flex-direction: column;

  &__search {
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 0.625rem;
  }
  &__search-input {
    height: 2.5rem;
    width: 100%;
  }

  &__subheader-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0.625rem 0.9375rem;
  }

  &__subheader-data-item {
    width: 50%;
    height: 37px;
    min-height: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #323232;

    &:not(:last-child) {
      margin: 0 1px 0 0;
    }
  }
  &__subheader-data-item-nick {
    margin: 0 0.625rem 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
    white-space: nowrap;
  }
  &__subheader-data-item-value {
    max-width: 3.125rem;
    overflow: auto;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFDF38;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem 0 1.25rem;
    background: #323232;
    margin: 0 0 1px 0;
  }
  &__item-text {
    max-width: 75%;
    overflow: auto;
    white-space: nowrap;
    margin: 0 0.625rem 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }
  &__item-balance {
    margin: 0 0.625rem 0 auto;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFDF38;
  }
  &__item-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}
