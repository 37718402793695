.notification {
  z-index: 100;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 13px;
  background: #2e323e;
  border: 1px solid #555962;
  box-shadow: 0 5px 5px rgba(46, 66, 98, 0.15);
  font-size: 16px;
  color: #ffffff;
  animation: 0.7s ease init;

  &_type {
    &_default {
    }

    &_error {
    }

    &_success {
    }
  }
  /* &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #000000;
    border-left: 1px solid #00d870;
    border-top: 1px solid #00d870;
    top: calc(-8px - 1px);
    right: 20.8px;
    transform: rotate(45deg);
  } */

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__left {
    height: 100%;
    flex-shrink: 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__icon-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 10px auto 0;
  }
  &__icon {
    width: 20px;
    height: 20px;
    display: flex;
  }
  &__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
  &__cross-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 auto auto;
  }
  &__cross {
    width: 13px;
    height: 13px;
    display: flex;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(160px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 64px;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 64px;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
