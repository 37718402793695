.tree {
  width: 100%;
  overflow-x: auto;
  padding: 0 0 6.25rem 0;
  color: #dddddd;

  &__back-block{
    margin: 0 0 1px 0;
  }
}
