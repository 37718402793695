.change-pas {
  position: relative;

  &__back-block {
  }
  &__main {
    position: relative;
    width: 100%;
    padding: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 7px;
  }
  &__item-title {
    width: 100%;
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #797b83;
  }
  &__item-field {
    widows: 100%;
    height: 40px;
  }

  &__button {
    width: 100%;
    height: 40px;
  }
  &__button-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #111522;
  }
}
