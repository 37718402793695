.header {
  width: 100%;
  min-height: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  background: #9b55e1;
  padding: 0 11px;
  justify-content: center;

  &_isAuth {
    justify-content: space-between;
  }

  &__logo-wrapper {
    // flex-grow: 1;
    // margin-right: auto;
    display: flex;
    // justify-content: flex-start;
  }
  &__logo {
  }
  &__logo-icon {
    width: 7.688rem;
    height: 2.75rem;
    display: flex;
  }

  &__login-button-block {
    height: 2.25rem;
  }
  &__user {
    justify-self: flex-end;
  }
  &__user-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__user-id {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__user-id-title {
    margin: 0 0.25rem 0 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 100%;
    text-align: right;
    color: #ffffff;
  }
  &__user-id-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #ffffff;
  }
  &__user-balance {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__user-balance-value {
    margin: 0 0.25rem 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #ffffff;
  }
  &__user-balance-currency {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #ffffff;
  }
}
