.paginator-mobile {
  user-select: none;
  min-width: 2.625rem;
  width: fit-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;

  &__page-item {
    cursor: pointer;
    width: 2.375rem;
    height: 2.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2E2E2E;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    color: #FFF;

    &:last-child {
      background: #323232;
      border-radius: 0 10px 10px 0;
    }

    &:first-child {
      background: #323232;
      border-radius: 10px 0 0 10px;
    }
  }
  // &__active {
  //   border: 1px solid #FFB401;
  //   color: #FFB401;
  // }

  &__arrow-left-container,
  &__arrow-right-container {
    width: 2.625rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-left,
  &__arrow-right {
    width: 1.9375rem;
    height: 1.875rem;
    display: flex;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
  &__arrow-right {
    transform: rotate(180deg);

    [dir="rtl"] & {
      transform: rotate(0deg);
    }
  }
}
