.total {
  &__content {
    width: 100%;
    padding: 1.3125rem 0.625rem 0.625rem 0.625rem;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797B83;
  }
  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px 8px;
    margin: 0 0 0.4375rem 0;
  }
  &__field-title {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797B83;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.625rem 0;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result {
    width: 100%;
  }
  &__result-header {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: #4D4D4D;
  }

  &__row {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: #444444;
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }
}
